import Container from 'react-bootstrap/Container';

function AppContato() {
  return (
    <section id="contato" className="block contact-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Contato</h2>
          <div className="subtitle">entre em contato e agende</div>
        </div>
        <div className='contact-info'>
          <ul>
            <li>
              <i className="fa-brands fa-whatsapp"></i>
              <a href="https://wa.me/5516993067022" target="_blank" rel="noopener noreferrer">Telefone: (16) 99306-7022</a>
              <br />
              <a href="https://wa.me/5516993067022" target="_blank" rel="noopener noreferrer">WhatsApp: (16) 99306-7022</a>
            </li>
            <li>
              <i className="fas fa-map-marker-alt"></i>
              <a href="https://www.google.com/maps/place/Studio+de+Pilates+Isabela+P%C3%A1dua/@-20.5300792,-47.3971906,15z/data=!4m6!3m5!1s0x94b0a7b9cc534137:0x5df47fca12076d3!8m2!3d-20.5300792!4d-47.3971906!16s%2Fg%2F11krjr_46g?entry=ttu" target="_blank" rel="noopener noreferrer">Rua Afonso Pena, 1381 - Sala 21</a>
              <br />
              <a href="https://www.google.com/maps/place/Studio+de+Pilates+Isabela+P%C3%A1dua/@-20.5300792,-47.3971906,15z/data=!4m6!3m5!1s0x94b0a7b9cc534137:0x5df47fca12076d3!8m2!3d-20.5300792!4d-47.3971906!16s%2Fg%2F11krjr_46g?entry=ttu" target="_blank" rel="noopener noreferrer">Cidade Nova - Franca/SP</a>
            </li>
          </ul>
        </div>
      </Container>
      <div className='google-map'>
        <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3736.149570744484!2d-47.40638002384091!3d-20.541060257629315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94b0a7b9cc534137%3A0x5df47fca12076d3!2sStudio%20de%20Pilates%20Isabela%20P%C3%A1dua!5e0!3m2!1spt-BR!2sbr!4v1709816391454!5m2!1spt-BR!2sbr"></iframe>
      </div>
    </section>
  );
}

export default AppContato;