import Container from 'react-bootstrap/Container';

import logowpp from '../assets/images/whatsappicon.png';
import FloatingButton from "./floatingbutton";

function AppFooter() {
  return (
    <Container fluid>
      <div className="copyright">Copyright &copy; 2024 Studio Isabela Pádua | Desenvolvido por GM.</div>
      <div className="socials">
        <ul>
          <li><a href="https://www.facebook.com/bella.padua" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a></li>
          <li><a href="https://www.instagram.com/isabelapadua.fisio" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a></li>
          <li><a href="https://www.linkedin.com/in/isabela-p%C3%A1dua-b18ab8220/?originalSubdomain=br" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a></li>
        </ul>
      </div>
      <FloatingButton
        imageUrl={logowpp}
        linkUrl="https://wa.me/5516993067022"
      />
    </Container>
  )
}

export default AppFooter;