import React from 'react';

const FloatingButton = ({ imageUrl, linkUrl }) => {
  const handleClick = () => {
    window.open(linkUrl, '_blank');
  };

  return (
    <button
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: '1000', // Garante que o botão esteja na parte superior
        border: 'none',
        borderRadius: '50%',
        width: '65px',
        height: '65px',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Adiciona uma sombra
      }}
      onClick={handleClick}
    >
      <img
        src={imageUrl}
        alt="Floating Button"
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
    </button>
  );
};

export default FloatingButton;
