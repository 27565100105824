import Carousel from 'react-bootstrap/Carousel';

var heroData = [
    {
      id: 1,
      image: require('../assets/images/carousel1.jpeg'),
      title: 'Força e Flexibilidade',
      description: 'Descubra como o Pilates pode fortalecer e alongar seu corpo, proporcionando uma sensação de equilíbrio e bem-estar.',
      link: 'https://wa.me/5516993067022'
    },
    {
      id: 2,
      image: require('../assets/images/carousel2.jpeg'),
      title: 'Postura Perfeita',
      description: 'Melhore sua postura e movimentos com Pilates, desenvolvendo uma base sólida para uma vida saudável e ativa.',
      link: 'https://wa.me/5516993067022'
    },
    {
      id: 3,
      image: require('../assets/images/carousel3.jpeg'),
      title: 'Equilíbrio Total',
      description: 'Alcance o equilíbrio físico e mental com Pilates, uma prática que nutre corpo, mente e alma para um estilo de vida mais harmonioso.',
      link: 'https://wa.me/5516993067022'
    }
  ]
  


export default function AppHero() {
    return (
        <section id="inicio" className='hero-block'>
          <Carousel>
            {
                heroData.map(hero => {
                    return (
                        <Carousel.Item key={hero.id}>                            
                            <img
                            className="d-block w-100"
                            src={hero.image}
                            alt={"Slide " + hero.id}
                            />
                            <Carousel.Caption>
                                <h3>{hero.title}</h3>
                                <p>{hero.description}</p>
                                <a className="btn btn-primary" href={hero.link} target="_blank" rel="noopener noreferrer">Tire suas dúvidas <i className="fas fa-chevron-right"></i></a>
                            </Carousel.Caption>
                        </Carousel.Item>
                    )
                })
            }
          </Carousel>
        </section>
    )
}