import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const servicesData = [
  {
    id: 1,
    icon: 'fa-solid fa-street-view',
    title: 'Pilates',
    description: 'O pilates fortalece músculos, melhora a flexibilidade, promove melhor postura e equilíbrio, além de reduzir o estresse e proporcionar bem-estar físico e mental.'
  },
  {
    id: 2,
    icon: 'fa-solid fa-person-pregnant',
    title: 'Pilates para Gestantes',
    description: 'O pilates para gestantes fortalece o corpo, especialmente o períneo e o core, melhora a postura, alivia dores comuns da gravidez e promove relaxamento, preparando para o parto.'
  },
  {
    id: 3,
    icon: 'fa-solid fa-x-ray',
    title: 'Quiropraxia',
    description: 'A quiropraxia oferece alívio da dor, promove a correção da postura e melhora a função neuromuscular, resultando em melhor qualidade de vida e bem-estar físico e mental.'
  },
  {
    id: 4,
    icon: 'fa-solid fa-staff-snake',
    title: 'Fisioterapia',
    description: 'A fisioterapia proporciona recuperação e reabilitação física, alívio da dor, melhora da mobilidade e prevenção de lesões, promovendo melhor qualidade de vida e bem-estar geral.'
  }
]

function AppServicos() {
  return (
    <section id="servicos" className="block services-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Nossos Serviços</h2>
          <div className="subtitle">o que oferecemos</div>
        </div>
        <Row>
          {
            servicesData.map(services => {
              return (
                <Col sm={6} className='holder' key={services.id}>
                  <div className="icon">
                    <i className={services.icon}></i>
                  </div>
                  <h3>{services.title}</h3>
                  <p>{services.description}</p>
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </section>
  );
}

export default AppServicos;