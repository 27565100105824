import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Pagination from 'react-bootstrap/Pagination';

const worksData = [
  {
    id: 1,
    link: 'https://wa.me/5516993067022',
    image: require('../assets/images/fotos1.jpeg'),
    title: 'Quiropraxia',
    subtitle: 'Clique para agendar'
  },
  {
    id: 2,
    link: 'https://wa.me/5516993067022',
    image: require('../assets/images/fotos2.jpeg'),
    title: 'Pilates',
    subtitle: 'Clique para agendar'
  },
  {
    id: 3,
    link: 'https://wa.me/5516993067022',
    image: require('../assets/images/fotos3.jpeg'),
    title: 'Pilates',
    subtitle: 'Clique para agendar'
  },
  {
    id: 4,
    link: 'https://wa.me/5516993067022',
    image: require('../assets/images/fotos4.jpeg'),
    title: 'Pilates',
    subtitle: 'Clique para agendar'
  },
  {
    id: 5,
    link: 'https://wa.me/5516993067022',
    image: require('../assets/images/fotos5.jpeg'),
    title: 'Pilates',
    subtitle: 'Clique para agendar'
  },
  {
    id: 6,
    link: 'https://wa.me/5516993067022',
    image: require('../assets/images/fotos6.jpeg'),
    title: 'Pilates',
    subtitle: 'Clique para agendar'
  }
]

let active = 2;
let items = [];
for (let number = 1; number <= 5; number++) {
  items.push(
    <Pagination.Item key={number} active={number === active}>
      {number}
    </Pagination.Item>,
  );
}

function AppFotos() {
  return (
    <section id="fotos" className="block works-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Fotos</h2>
          <div className="subtitle">nossos atendimentos</div>
        </div>
        <Row className='portfoliolist'>
          {
            worksData.map(works => {
              return (
                <Col sm={4} key={works.id}>
                  <div className='portfolio-wrapper'>
                    <a href={works.link} target="_blank" rel="noopener noreferrer">
                      <Image src={works.image} />
                      <div className='label text-center'>
                        <h3>{works.title}</h3>
                        <p>{works.subtitle}</p>
                      </div>
                    </a>
                  </div>
                </Col>
              );
            })
          }
        </Row>
        {/* <Pagination>{items}</Pagination> */}
      </Container>  
    </section>
  );
}

export default AppFotos;